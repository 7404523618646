import React, { useState, useEffect } from "react";
import "./InstagramCourses.css";
import DOMPurify from 'dompurify';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import DeleteModal from "../../Components/DeleteModal/DeleteModal";
import { Navigation } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getInstagramCourses } from "../../services/redux/middleware/getInstagramCourses";
// import { getCourse } from "../../services/redux/middleware/getCourseWithType";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";




const InstagramCourses = () => {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedLecture, setSelectedLecture] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [courseData, setCourseData] = useState([]);
  const dispatch = useDispatch();

  const instagramCoursesData = useSelector(
    (state) =>
      state?.getInstagramCourses?.getInstagramCoursesData?.data
  );


  console.log("mujeeb", instagramCoursesData);
  const handleEditCourse = (courseid) => {
    const type="instagram";
    console.log("editableid", courseid);
    navigate(`/CourseEditing/${type}`, { state: { courseId: courseid } });
  }
  const [check, setchek] = useState(false);
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    console.log("ali");
    dispatch(getInstagramCourses("Instagram"));


    setSelectedLecture(null);
  };


  useEffect(() => {

    const run = async () => {
      setIsLoading(true);
      console.log("abaaaac");
      await dispatch(getInstagramCourses("Instagram"));

      setIsLoading(false);
      setchek(false)
    };

    run();
  }, [dispatch, check]);

  useEffect(() => {
    if (instagramCoursesData) {
      setCourseData(instagramCoursesData);
    }
    // console.log("Instagram courses data is...", instagramCoursesData);

  }, [instagramCoursesData]);

  console.log(courseData)
  const handleDeleteClick = (lectureId) => {
    setSelectedLecture(lectureId);
    console.log("id of selected course", lectureId);
    setShowDeleteModal(true);
  };



  const handleAddCourse = () => {
    navigate("/AddCourse?type=Instagram");
  };
  return (
    <>
      <div
        className="coursesmain"
        style={{
          backgroundColor: "#121212",
          minHeight: "100vh",
          padding: "20px",
        }}
      >
        <div className="instadiv">
          <p className="instadivptag">Instagram Courses</p>
          <button className="insta" onClick={handleAddCourse}>
            <img src="/Images/instagram/plus.svg" alt="" />
            Add Course
          </button>
        </div>
        {/* Responsive Table */}
        {isLoading ? (
          <ScreenLoader />
        ) : (
          <>
            {instagramCoursesData === undefined ? (
              <div className="empyttytopmain">
                <img src="/Images/empty.svg" alt="nn" />
                <p className="emptytexttt">There’s no Instagram Courses</p>
              </div>
            ) : (

              <>

                <TableContainer
                  component={Paper}
                  className="InstagramCourses_tableMain"
                >
                  <Table sx={{ minWidth: 640 }} aria-label="simple table">
                    <TableHead className="InstagramCourses_tableMain_head">
                      <TableRow style={{ borderRadius: "8px" }}>
                        <TableCell className="InstagramCourses_tableMain_cell1pic">
                          Image
                        </TableCell>
                        <TableCell className="InstagramCourses_tableMain_cell1">
                          Title
                        </TableCell>
                        <TableCell className="InstagramCourses_tableMain_cell1">
                          Description
                        </TableCell>
                        <TableCell className="InstagramCourses_tableMain_cell1">
                          Section
                        </TableCell>
                        {/* <TableCell className="InstagramCourses_tableMain_cell1">
                  Duration
                </TableCell> */}
                        <TableCell className="InstagramCourses_tableMain_cell1">
                          Date
                        </TableCell>
                        <TableCell className="InstagramCourses_tableMain_cell1">
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>


                    <TableBody>
                      <TableRow>
                        <TableCell
                          colSpan={4}
                          style={{
                            height: "16px",
                            backgroundColor: "transparent",
                            borderBottom: "none",
                            padding: "0px",
                          }}
                        ></TableCell>

                      </TableRow>

                      {courseData?.courses?.map((row, index) => (
                        <TableRow key={index} className="InstagramCoursesTable_row">
                          <TableCell className="InstagramCoursesTable_row_cell">
                            <img
                              src={row?.thumbnailImage}
                              alt=""
                              variant="square"
                              style={{
                                width: 40,
                                height: 40,
                                borderRadius: "8px",
                                border: "1px solid #0F6FFF",
                                objectFit: "cover"
                              }}
                            />
                          </TableCell>
                          <TableCell className="InstagramCoursesTable_row_cell">
                            {row?.title}
                          </TableCell>
                          <TableCell className="InstagramCoursesTable_row_cell1">

                            <p
                              dangerouslySetInnerHTML={{
                                __html: (() => {
                                  const rawHtml = row?.description || ""; // Get the raw HTML string

                                  // Create a temporary DOM element to parse HTML
                                  const tempDiv = document.createElement("div");
                                  tempDiv.innerHTML = rawHtml;

                                  // Extract the plain text (without HTML tags)
                                  const plainText = tempDiv.textContent || tempDiv.innerText || "";

                                  // Slice the plain text to 30 characters
                                  const slicedText = plainText.slice(0, 20);

                                  // Rebuild the HTML from the original raw HTML
                                  let remainingHtml = rawHtml;

                                  // If the original plain text was longer than 30, add ellipsis
                                  if (plainText.length > 20) {
                                    remainingHtml = slicedText + "...";
                                  }

                                  // Return the HTML-safe string
                                  return remainingHtml;
                                })(),
                              }}
                            />

                          </TableCell>
                          <TableCell className="InstagramCoursesTable_row_cell1">
                            {row?.sections.length}
                          </TableCell>
                          {/* <TableCell className="InstagramCoursesTable_row_cell1">
                      {row?.courseDuration}
                    </TableCell> */}
                          <TableCell
                            style={{ whiteSpace: "nowrap" }}
                            className="InstagramCoursesTable_row_cell1"
                          >
                            {row?.createdAt.split("T")[0]}
                          </TableCell>
                          <TableCell className="InstagramCoursesTable_row_cell">
                            <div className="InstagramCoursesTable_row_cell2">
                              <img
                                src="/Images/instagram/deleteicon.svg"
                                alt=""
                                onClick={() => handleDeleteClick(row?._id)}
                                style={{ cursor: "pointer" }}
                              />
                              <img src="/Images/instagram/write.svg" alt=""
                                onClick={() => handleEditCourse(row?._id)}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}

                    </TableBody>


                  </Table>
                </TableContainer>

              </>
            )}
          </>
        )}
        <DeleteModal
          show={showDeleteModal}
          handleClose={handleCloseDeleteModal}
          taskId={selectedLecture}
        />
      </div>
    </>
  );
};

export default InstagramCourses;
